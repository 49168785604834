import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GatsbyImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
  heroImgBg: {
    position: "absolute",
    maxWidth: "500px",
    right: "-3%",
    top: "-60px",
    zIndex: 9999,
    [theme.breakpoints.down("md")]: {
      right: "-15%",
      top: "-60px",
      maxWidth: "520px",
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "0%",
      top: "50px",
      maxWidth: "608px",
      width: '100%',
    },
    [theme.breakpoints.down("xs")]: {
      top: "30px",
      maxWidth: "100%",
    },
  },

  heroImgBgSchedulingSoftware: {
    position: "absolute",
    right: "-10%",
    bottom: "-80%",
    zIndex: 9999,
    [theme.breakpoints.down("md")]: {
      right: "-15%",
      bottom: "-60%",
      maxWidth: "530px",
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: 0,
      bottom: "-50px",
      maxWidth: "608px",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "0",
      maxWidth: "100%",
    },
  },

  heroImgBgSupport: {
    position: "absolute",
    right: "-93px",
    bottom: "-233px",
    zIndex: 9999,
    [theme.breakpoints.down("md")]: {
      right: "-85px",
      bottom: "-211px",
      maxWidth: "530px",
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: 0,
      bottom: "-83px",
      maxWidth: "608px",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: "0",
      maxWidth: "100%",
      marginTop: "50px",
    },
  },
}));

export const ImgRight = ({ heroImage, id }) => {
  const classes = useStyles();
  const md = useMediaQuery("(max-width: 960px)");
  return (
    <Grid
      container
      item
      direction="column"
      // justifyContent='flex-start'
      alignItems={md ? "center" : "flex-start"}
      xs={12}
      md={5}
    >
      <GatsbyImage
        image={heroImage}
        alt="hero image collage"
        className={
          id === "b720ff6c-323c-45e9-9d28-76e9904a29f5"
            ? classes.heroImgBgSupport
            : id === "c4f9a546-7741-482d-b5e5-1aa284a8b25d"
            ? classes.heroImgBgSchedulingSoftware
            : classes.heroImgBg
        }
      />
    </Grid>
  );
};
